/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your custom styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */
}

.title-with-shadow {
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
}

